body .rti--container {
  --rti-bg: transparent;
  --rti-border: rgb(44, 53, 67);
  --rti-main: #3182ce;
  --rti-radius: 0.375rem;
  --rti-s: 0.5rem;
  --rti-tag: rgb(44, 53, 67);
  --rti-tag-remove: #e53e3e;
}

.rti--input {
  background-color: transparent;
  color: white !important;
}

.rti--container {
  border-width: 2px !important;
  padding: 12px 25px !important;
  border-radius: 10px !important;
}
