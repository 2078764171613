::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #161924;
}

::-webkit-scrollbar-thumb {
  background: rgb(49, 58, 73);
  border-radius: 4px;
}

* {
  box-sizing: border-box !important;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  font-size: 16px;
  color: #fff;

  .text-muted {
    color: #6c757d !important;
  }
}

a {
  text-decoration: none;
}

a:hover {
  color: inherit;
}

.alice-carousel {
  margin: 0;
}

.qr-reader > section {
  border-radius: 8px;
}

.fs-18 {
  font-size: 18px;
  line-height: 1.3;
}

.fs-16 {
  font-size: 16px;
  line-height: 1.3;
}

.plyr.plyr--video {
  border-radius: 8px;
}

.mh-mx-content {
  max-height: max-content !important;
}

.h-mx-content {
  height: max-content !important;
}

div .banner-item {
  width: 140px;
  height: max-content !important;
}

.scroll-snap {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
}
.scroll-snap-item {
  scroll-snap-align: center;
}

/*ANTD CUSTOMIZING COMPONENTS*/

.ant-upload-scroll .ant-upload-list.ant-upload-list-picture-card {
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  @extend .scroll-snap;

  .ant-upload-list-item-container {
    flex: 0 0 33.33%;
    @extend .scroll-snap-item;

    .ant-upload-list-item-thumbnail {
      border-radius: 8px;
    }
  }
}

.ant-upload-list-item-actions {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.ant-transfer-list {
  width: 100% !important;
}

.ant-modal-content {
  background-color: #161924 !important;
}

.ant-modal-content {
  padding-bottom: 0.2rem !important;
}

.ant-input,
.ant-picker.ant-picker-range .ant-picker-input > input,
.ant-picker input,
.ant-select > div.ant-select-selector,
.ant-input-number {
  display: block !important;
  width: 100% !important;
  border: 2px solid rgb(44, 53, 67);
  border-radius: 10px !important;
  padding: 10px 25px !important;
  background-color: transparent !important;
  height: max-content !important;

  &:disabled {
    filter: opacity(0.6);
    cursor: not-allowed;
  }
}

.ant-input-number .ant-input-number-handler-wrap {
  background: #0006;
}

.ant-picker:not(.ant-picker-range) {
  background-color: transparent !important;
  border: 2px solid rgb(44, 53, 67) !important;
  border-radius: 10px !important;
}

.ant-picker.ant-picker-range .ant-picker-input > input:focus {
  border-color: #e842a0;
  box-shadow: 0 0 0 2px rgba(244, 100, 194, 0.18);
  border-inline-end-width: 1px;
  outline: 0;
}

.ant-picker.ant-picker-range {
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  .ant-picker-active-bar {
    display: none;
  }
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-btn {
  height: 38px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &.ghost {
    background-color: rgb(44, 53, 67);
    border: 1px solid rgb(33, 41, 54);

    &:hover:not(:disabled) {
      background-color: rgb(33, 41, 54);
      border-color: rgb(33, 41, 54);
      color: white;
    }
  }
}

.ant-input-number.inputnumber-padding-0 {
  padding: 0 !important;
}

.disable-asterik .ant-form-item-label > label::before {
  content: "" !important;
  display: none !important;
}

.ant-collapse-content-box {
  background-color: rgb(44, 53, 67);
  margin-top: 0.4rem;
  border-radius: 8px;
}

.ant-collapse-item {
  background-color: transparent !important;
}

.ant-collapse.gradient > .ant-collapse-item > .ant-collapse-header {
  background-color: rgb(44, 53, 67);
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
}

.ant-menu {
  height: max-content;
}

.ant-space-item {
  flex: 1 1 0;
}

.ant-table {
  background-color: transparent !important;

  table {
    border-radius: 8px !important;
    border-collapse: separate;
  }

  .ant-table-row {
    background-color: #11161f;
  }
  .ant-table-thead {
    background: rgb(44, 53, 67);
    border-radius: 8px !important;

    th.ant-table-cell {
      border-radius: 0 !important;
      background-color: transparent;
    }
  }
  .ant-table-cell {
    border-bottom: 0 !important;
  }
}
/*============================*/
.object-cover {
  object-fit: cover;
  object-position: center;
}

.header {
  min-height: 80px;
  max-height: 80px;
  background-color: transparent;
  @media screen and (max-width: 992px) {
    max-height: max-content;
    height: auto;
  }
}

.wrapper,
.header {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.admin-panel-user-info {
  width: 310px;
}

.admin-panel-player-info {
  width: 475px;
}

.admin-panel-user-rewards {
  width: 565px;
}

.admin-layout {
  display: flex;
  width: 100%;
  height: 100%;

  .admin-layout-content {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    gap: 0.25rem;
    width: 100%;
    max-width: calc(100% - 290px);

    @media screen and (max-width: 992px) {
      max-width: 100%;
      padding-left: 0;
    }
  }

  @media screen and (max-width: 992px) {
    margin-top: 1rem;
  }
}

.admin-panel {
  display: flex;
  gap: 20px;
  width: 100%;

  .admin-panel-navbar {
    $w: 400px;
    flex: 0 0 $w;
    max-width: $w;
    height: max-content;
  }

  .admin-panel-content {
    flex: 0 0 calc(100% - 400px - 20px);
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 0;

    .admin-panel-navbar {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      max-width: 100%;
      gap: 10px;
    }
  }
}

.admin-panel-searchbar-results {
  font-size: 13px;
}

.ant-select.admin-panel-temporal-missions-filter > .ant-select-selector {
  padding: 0.2rem 1rem !important;
}

.admin-panel-pagination-page {
  padding: 7px !important;
  max-width: 70px;
}

@media screen and (max-width: 992px) {
  .ant-picker-panels {
    flex-direction: column;
  }

  .admin-panel-nav-menu {
    margin-top: 0.5rem;
    flex-direction: column;
    > a {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  .nav-temp {
    flex-wrap: wrap;

    > a {
      display: block;
      flex: 1 1 auto;
      button {
        display: block;
        width: 100%;
      }
    }
  }

  .admin-panel-menu-open .admin-panel-close-menu {
    display: block;
  }

  .admin-panel-user-info,
  .admin-panel-player-info,
  .admin-panel-user-rewards {
    width: 100%;
  }

  .admin-panel-user-progress {
    flex-direction: column;
  }
}

@media screen and (max-width: 576px) {
  .ant-alert-icon {
    display: none;
  }

  .admin-panel-episodes .ant-collapse-content-box {
    padding: 5px !important;
  }

  .admin-panel-episode-description {
    font-size: 14px;
  }

  .campaign-photo {
    width: 100%;
    max-width: 100%;
  }

  .admin-panel-campaign-name {
    font-size: 1.2rem !important;
  }

  .ant-modal {
    width: 100% !important;

    .ant-modal-content {
      padding: 10px 12px !important;
    }
  }

  .admin-panel-episode-title,
  .admin-panel-episode-xp,
  .admin-panel-apikey,
  .admin-panel-episode-xp {
    font-size: 14px !important;
  }

  .admin-panel-episode-name {
    font-size: 16px !important;
  }

  .admin-panel-episode-xp {
    flex: 0 0 65px;
    margin-left: auto;
    display: block;
    text-align: right;
  }

  .admin-panel-searchbar-results {
    font-size: 11px;
  }
}

.admin-panel-close-menu {
  display: none;
  font-size: 1rem;
  z-index: 99999;
  top: 0;
  right: 0;
}

.ant-menu.admin-panel-menu {
  flex: 0 0 290px;
  background-color: rgb(33 41 54) !important;
  border: 1px solid rgb(44, 53, 67) !important;
  padding: 1rem 0.8rem 0.8rem 0.8rem !important;
  border-radius: 8px;
  transition: right 200ms cubic-bezier(0.075, 0.82, 0.165, 1);

  > li.ant-menu-item {
    border-radius: 5px !important;
    margin-bottom: 0.5rem;
    padding: 0.3rem 0.8rem;
    height: auto;
    display: flex;
    align-items: center;
    &:active {
      background-color: rgba(255, 255, 255, 0.12) !important;
    }
  }

  @media screen and (max-width: 992px) {
    position: fixed;
    z-index: 9999;
    right: -9999px;
    top: 0;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    height: 100%;
    overflow-y: auto;
  }
}

.admin-panel-menu-open .ant-menu.admin-panel-menu {
  right: 0;
}

.box {
  display: flex;
  flex-direction: column;
  background-color: rgb(33, 41, 54);
  border: 1px solid rgb(44, 53, 67);
  padding: 1rem;
  border-radius: 4px;
  width: max-content;
  max-width: 100%;
  position: relative;
  height: 100%;

  &.h-auto {
    height: auto;
  }

  .box {
    height: max-content;
  }
}

.box-look {
  background-color: rgb(33, 41, 54);
  border: 1px solid rgb(44, 53, 67);
  border-radius: 4px;
}

.spinner-antd > span {
  font-size: 4rem !important;
}

.maxWidth {
  max-width: 1280px !important;
}

.form-title {
  margin-top: 0;
}

.layout {
  min-height: 100vh;
  width: 100%;
}

.gradientBg {
  background: linear-gradient(92.02deg, #ff792e -1.27%, #ff1fa5 100.92%);
}
.bg-green {
  background: #35ff1f41;

}
.gradient.ant-card .ant-card-head {
  background: linear-gradient(92.02deg, #ff792e -1.27%, #ff1fa5 100.92%) !important;
}

.gradient.ant-btn {
  background: linear-gradient(92.02deg, #ff792e -1.27%, #ff1fa5 100.92%) !important;
}

.ant-menu.gradient > .ant-menu-item-selected {
  background: linear-gradient(92.02deg, #ff792e -1.27%, #ff1fa5 100.92%) !important;
  color: #fff;
}

.ant-list-item.missions {
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s all;
}

.ant-list-item.missions:hover {
  background-color: #2c3543;
}

.ant-card.modified-card > .ant-card-actions > li {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* (temporal) */
.button-default {
  color: #ff792e;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  border: none;
  padding: 10px 15px;
  background-color: rgba(220, 29, 143, 0.3);
  height: auto;
}

.ant-menu.gradient > .ant-menu-item {
  border-radius: 0;
  margin-bottom: 1rem;
  height: 35px;
  line-height: 35px;
}

.text-pink {
  color: #dc1d8f;
}

.text-orange {
  color: #ff792e;
}

.cursor-pointer {
  cursor: pointer;
}

.rewards-scroll-container {
  overflow-y: scroll;
  height: auto;
  max-height: 506px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.reward-hover {
  opacity: 0;
  transition: 0.2s opacity;

  &:hover {
    opacity: 0.9;
  }
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: transparent radial-gradient(circle, transparent 1%, transparent 1%) center/15000%;
}
.ripple:active {
  background-color: #11151d;
  background-size: 100%;
  transition: background 0s;
}
